import React, { ComponentProps, ReactNode, useMemo } from 'react'
import { AnyVariables, DocumentInput } from 'urql'
import { IPromiseSearchOption } from '~/core/@types/global'
import ComboboxSelect from '~/core/ui/ComboboxSelect'
import { ISelectOption } from '~/core/ui/Select'
import {
  createFilterControl,
  createOptionFetcher,
  FilterControlItemType
} from '.'

export interface OptionDocument<Data, Variables> {
  documentNode: DocumentInput<Data, Variables>
  variable: ((searchParams: IPromiseSearchOption) => Variables) | Variables
  mapping: (data: Data) => {
    collection: {
      value: string
      supportingObj?: {
        name?: string
        descriptionHelpName?: string
        description?: string
        shortName?: string
      }
      parentId?: string
    }[]
    metadata: { totalCount: number }
  }
}
export interface FilterItemComboboxPropTypeDocumentNode<
  Data = any,
  Variables extends AnyVariables = AnyVariables
> extends ComponentProps<typeof ComboboxSelect>,
    FilterControlItemType {
  optionsFromDocumentNode?: OptionDocument<Data, Variables>
}

interface PropsType {
  value: ISelectOption | ISelectOption[]
  options?:
    | ISelectOption[]
    | ((params: IPromiseSearchOption) => Promise<{
        metadata?:
          | {
              totalCount: number
            }
          | undefined
        collection: never[]
      }>)
    | undefined
  onChange?: (value?: any, options?: { triggerFilterChange: boolean }) => void
}

export const FilterItemCombobox = <
  Data = any,
  Variables extends AnyVariables = AnyVariables
>(
  props: Omit<
    FilterItemComboboxPropTypeDocumentNode<Data, Variables>,
    'value' | 'onChange'
  >
) => {
  const Control = useMemo(() => {
    const optionsFromDocumentNode = props.optionsFromDocumentNode
    return createFilterControl<
      FilterItemComboboxPropTypeDocumentNode<Data, Variables>
    >(
      //@ts-ignore
      optionsFromDocumentNode
        ? createOptionFetcher((props: PropsType) => {
            return (
              <ComboboxSelect
                {...props}
                value={props.value || null}
                onChange={(value) => {
                  return (
                    props.onChange &&
                    props.onChange(value, { triggerFilterChange: true })
                  )
                }}
                options={props.options}
              />
            )
          })
        : (props) => {
            return (
              <ComboboxSelect
                {...props}
                value={props.value || null}
                onChange={(value) => {
                  props.onChange(value, { triggerFilterChange: true })
                }}
              />
            )
          }
    )
  }, [])
  return <Control {...props} />
}

export default FilterItemCombobox
