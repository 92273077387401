"use client"

import { forwardRef, useCallback, useEffect, useState } from "react"
import { CloseCircleFill } from "~/core/ui/FillIcons"
import IconWrapper from "~/core/ui/IconWrapper"
import { Input, InputOptionsProps } from "~/core/ui/Input"
import { InputLeftAddon, InputRightAddon } from "~/core/ui/InputAddon"
import { InputGroup } from "~/core/ui/InputGroup"
import { cn, debounce } from "~/core/ui/utils"

interface DebouncedInputProps extends InputOptionsProps {
  onClear?: () => void
  debounceDelay?: number
  value: string | number
  onChange: (value: string | number) => void
}

const DebouncedInput = forwardRef<HTMLInputElement, DebouncedInputProps>(
  ({
    value: initialValue,
    onChange,
    onClear,
    debounceDelay = 500,
    size = "sm",
    ...props
  }) => {
    const [value, setValue] = useState(initialValue)
    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadDebounced = useCallback(
      debounce(async (search: string) => {
        onChange(search)
      }, debounceDelay),
      []
    )

    const getSizeSearchIcon = {
      xs: 14,
      sm: 16,
      md: 16,
      lg: 18,
    }

    const getPositionSearchIcon = {
      xs: "left-2",
      sm: "left-2.5",
      md: "left-2.5",
      lg: "left-3",
    }

    const customInputClass = {
      xs: "px-[30px]",
      sm: "px-9 ",
      md: "px-9",
      lg: "px-[42px]",
    }

    const getPositionClearIcon = {
      xs: "right-[5px]",
      sm: "right-2",
      md: "right-2",
      lg: "right-3",
    }

    const getPadding = {
      left: {
        xs: " pl-[30px]",
        sm: " pl-9",
        md: " pl-9",
        lg: " pl-[42px]",
      },
      right: {
        xs: " pr-[30px]",
        sm: " pr-9",
        md: " pr-9",
        lg: " pr-[42px]",
      },
    }

    return (
      <InputGroup
        size={size}
        configPadding={{
          left: getPadding.left[size],
          right: getPadding.right[size],
        }}
      >
        <InputLeftAddon className={`${getPositionSearchIcon[size]}`}>
          <IconWrapper size={getSizeSearchIcon[size]} name="Search" />
        </InputLeftAddon>
        <Input
          autoFocus={props?.autoFocus}
          className={cn(`${customInputClass[size]}`, value ? '' : 'pr-0')}
          placeholder={props.placeholder}
          value={value}
          onChange={(value) => {
            setValue(value)
            loadDebounced(value)
          }}
        />
        {value ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              setValue("")
              onClear && onClear()
            }}
          >
            <InputRightAddon className={`${getPositionClearIcon[size]}`}>
              <CloseCircleFill
                size={getSizeSearchIcon[size]}
                className={`fill-gray-400 dark:fill-gray-500`}
              />
            </InputRightAddon>
          </div>
        ) : undefined}
      </InputGroup>
    )
  }
)

DebouncedInput.displayName = "DebouncedInput"

export { DebouncedInput }
export type { DebouncedInputProps }
