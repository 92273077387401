'use client'

import { InputGroupProps } from '~/core/ui/InputGroup'
import { cn } from '~/core/ui/utils'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

const inputLeftAddonVariants = cva(
  'absolute top-1/2 -translate-y-1/2 bottom-0 inline-flex items-center justify-center',
  {
    variants: {
      size: {
        lg: 'w-[18px] h-[18px] left-[10px]',
        md: 'w-4 h-4 left-[10px]',
        sm: 'w-4 h-4 left-[10px]',
        xs: 'w-4 h-4 left-[10px]'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
)

const inputRightAddonVariants = cva(
  'absolute top-1/2 -translate-y-1/2 bottom-0 inline-flex items-center justify-center',
  {
    variants: {
      size: {
        lg: 'w-[18px] h-[18px] right-[10px]',
        md: 'w-4 h-4 right-[10px]',
        sm: 'w-4 h-4 right-[10px]',
        xs: 'w-4 h-4 right-[10px]'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
)

const InputLeftAddon = forwardRef<HTMLDivElement, InputGroupProps>(
  function InputLeftAddon(props, ref) {
    const { size, className, ...rest } = props

    return (
      <div
        ref={ref}
        className={cn(inputLeftAddonVariants({ size, className }))}
        {...rest}
      />
    )
  }
)
InputLeftAddon.displayName = 'InputLeftAddon'

const InputRightAddon = forwardRef<HTMLDivElement, InputGroupProps>(
  function InputRightAddon(props, ref) {
    const { size, className, ...rest } = props

    return (
      <div
        ref={ref}
        className={cn(inputRightAddonVariants({ size, className }))}
        {...rest}
      />
    )
  }
)

InputRightAddon.displayName = 'InputRightAddon'

export { InputLeftAddon, InputRightAddon }
