import { gql } from 'urql'

const QueryTenantDepartment = gql<
  {
    tenantDepartmentsList: {
      collection: {
        id: string
        name: string
        openJobsCount: number
        parentId: number
        subordinates: {
          id: string
          name: string
          openJobsCount: number
          parentId: number
        }[]
      }[]
      metadata: { totalCount: number }
    }
  },
  {}
>`
  query ($limit: Int, $page: Int, $search: String, $parentId: Int) {
    tenantDepartmentsList(
      limit: $limit
      page: $page
      search: $search
      parentId: $parentId
    ) {
      collection {
        id
        name
        openJobsCount
        parentId
        subordinates {
          id
          name
          parentId
          openJobsCount
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryTenantDepartment
