const MutationRejectApplicants = `
  mutation ($id: Int!, $status: ApplicantStatus, $rejectedReason: String, $rejectedReasonId: Int, $sendRejectEmail: Boolean, $subject: String, $cc: [String!],  $bcc: [String!], $htmlBody: String, $emailTemplateId: Int) {
    applicantsUpdate(
      input: {
        id: $id,
        status: $status,
        rejectedReason: $rejectedReason,
        rejectedReasonId: $rejectedReasonId,
        sendRejectEmail: $sendRejectEmail,
        subject: $subject,
        cc: $cc,
        bcc: $bcc,
        htmlBody: $htmlBody,
        emailTemplateId: $emailTemplateId
      }
    ) {
      applicant {
        id
        jobId
        profileId
        status
        statusDescription
        rejectedReasonLabel
        job {
          id
          title
          status
          statusDescription
          jobStages {
            id
            stageLabel
          }
          owner {
            email
            fullName
          }
        }
        createdBy {
          email
          fullName
          avatarVariants
        }
        jobStage {
          id
          stageLabel
        }
        currentStagedDate
        incoming
        tagLabel
      }
    }
  }
`

export default MutationRejectApplicants
