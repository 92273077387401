import { ReactElement, useMemo } from 'react'
import { createFilterControl, FilterControlItemType } from '.'
type ItemProp<T> = {
  children: (props: {
    value: T
    onChange: (value: T) => void
    onFilterSubmit?: () => void
  }) => ReactElement
} & FilterControlItemType
export const FilterItem = <T extends unknown>(props: ItemProp<T>) => {
  const Component = useMemo(
    () =>
      createFilterControl<ItemProp<T>, T>((props) => {
        return props.children({
          value: props.value,
          onChange: (value) =>
            props.onChange(value, { triggerFilterChange: true }),
          onFilterSubmit: props.onFilterSubmit
        })
      }),
    []
  )
  return <Component {...props} />
}
export default FilterItem
