import { ComponentProps, useCallback, useRef } from 'react'
import { DebouncedInput } from '~/core/ui/DebouncedInput'
import { Input } from '~/core/ui/Input'
import debounce from '~/core/utilities/debounce'
import { createFilterControl, FilterControl } from '.'

export const FilterItemSearchText = createFilterControl(
  (
    props: ComponentProps<typeof Input> &
      Partial<FilterControl<string>> & { typingDebounceSubmit?: number }
  ) => {
    const onFilterSubmitRef = useRef<() => void>()
    const onChangeRef = useRef<FilterControl<string>['onChange']>()
    onFilterSubmitRef.current = props.onFilterSubmit
    onChangeRef.current = props.onChange
    const callSubmit = useCallback(
      debounce(() => {
        onFilterSubmitRef?.current && onFilterSubmitRef?.current()
      }, props.typingDebounceSubmit),
      [props.typingDebounceSubmit]
    )

    const onChange = useCallback(
      (value: string | number) => {
        onChangeRef.current && onChangeRef.current(value as string)
        callSubmit()
      },
      [props.onChange, callSubmit]
    )
    return (
      <DebouncedInput
        {...props}
        value={props.value || ''}
        onChange={onChange}
        onClear={() => onChange('')}
      />
    )
  }
)
