import { ex } from '@fullcalendar/core/internal-common'
import { useMutation } from 'urql'
import { deletePlacementMutation } from './graphql/mutation-delete-placement'

const useDeletePlacement = () => {
  const [{ fetching: deleting }, deletePlacement] = useMutation(
    deletePlacementMutation
  )
  return { deletePlacement }
}
export default useDeletePlacement
