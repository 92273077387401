import { gql } from 'urql'

const QueryGetRejectReasonGroup = gql`
  query {
    rejectedReasonsListByGroup {
      rejectedByUs {
        id
        label
        rejectedKind
      }
      rejectedByCandidate {
        id
        label
        rejectedKind
      }
    }
  }
`

export default QueryGetRejectReasonGroup
