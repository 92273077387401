import { FC, useEffect, useMemo } from 'react'
import {
  Control,
  Controller,
  FormState,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  useWatch
} from 'react-hook-form'
import EmailEditor from '~/components/SendMailFormControl/EmailEditor'
import { Button } from '~/core/ui/Button'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { Toggle } from '~/core/ui/Toggle'
import { useQueryRejectReasonGroupList } from '~/lib/features/candidates/hooks/use-query-reject-reason-group'
import { ICandidateApplicant } from '~/lib/features/candidates/types'
import { formatContentPlaceholderEmailTemplate } from '~/lib/features/candidates/utilities'
import { removeHTMLTags } from '~/lib/features/careers/[id]/utilities'
import { IEmailTemplate } from '~/lib/features/settings/email-templates/types'
import { IEmailForm } from '../SendMailFormControl/EmailContentEditor'
import { useTranslation } from 'react-i18next'

const SubmitRejectCandidateForm: FC<{
  email?: string[]
  isLoading?: boolean
  setOpen: (open: boolean) => void
  isLoadingUpdateEmailProfile: boolean
  defaultTemplateEmail?: IEmailTemplate
  formState: FormState<IEmailForm>
  control: Control
  setValue: UseFormSetValue<IEmailForm>
  setError: UseFormSetError<IEmailForm>
  placeholderValue?: { [key: string]: string }
  setApplicant: (param?: ICandidateApplicant) => void
  applicant?: ICandidateApplicant
  clearErrors: UseFormClearErrors<IEmailForm>
  onSubmitUpdateEmailProfile: (params: { email: string }) => Promise<void>
}> = ({
  email,
  control,
  setOpen,
  setValue,
  setError,
  formState,
  isLoading,
  clearErrors,
  setApplicant,
  applicant,
  placeholderValue,
  defaultTemplateEmail,
  onSubmitUpdateEmailProfile,
  isLoadingUpdateEmailProfile
}) => {
  const { t } = useTranslation()
  const isSendRejectMessage = useWatch({
    control,
    name: 'sendRejectEmail'
  })

  const {
    trigger: fetchRejectReasonGroup,
    data: dataRejectReasonGroup,
    isLoading: isLoadReject
  } = useQueryRejectReasonGroupList({
    variables: {}
  })

  useEffect(() => {
    fetchRejectReasonGroup()
  }, [])

  useEffect(() => {
    if (!defaultTemplateEmail) {
      return
    }

    const { content: formattedBody } = formatContentPlaceholderEmailTemplate(
      defaultTemplateEmail.body || '',
      placeholderValue || {}
    )
    setValue('htmlBody', formattedBody)

    const { content: formattedSubject } = formatContentPlaceholderEmailTemplate(
      defaultTemplateEmail.subject || '',
      placeholderValue || {}
    )
    setValue('subject', removeHTMLTags(formattedSubject))

    defaultTemplateEmail?.id &&
      setValue('emailTemplate', { value: String(defaultTemplateEmail?.id) })
  }, [defaultTemplateEmail, setValue, placeholderValue])

  useEffect(() => {
    if (!isSendRejectMessage) {
      setValue('htmlBody', '')
      setValue('subject', '')
      setValue('cc', [])
      setValue('bcc', [])
    }
  }, [isSendRejectMessage, setValue])

  useEffect(() => {
    if (!isSendRejectMessage) return
    const listEmail = (email || []).filter((e) => e !== '')
    if (listEmail.length > 0) {
      setValue(
        'to',
        listEmail.map((i) => ({
          value: i,
          supportingObj: {
            name: i
          }
        }))
      )
    }
  }, [email, isSendRejectMessage, setValue])

  const disableSubmitRejectButton = useMemo(() => {
    if (!isSendRejectMessage) return false
    const listEmail = (email || []).filter((e) => e !== '')
    return listEmail.length === 0
  }, [isSendRejectMessage, email])

  const isShowComposeEmail = useMemo(() => {
    const listEmail = email?.filter((e) => e !== '') || []
    return listEmail.length > 0
  }, [email])

  return (
    <div>
      <div className="mb-5">
        <Controller
          control={control}
          name="rejectedReason"
          render={({ field: { onChange, value } }) => {
            return (
              <FormControlItem
                destructive={
                  formState.errors && !!formState.errors.rejectedReason
                }
                destructiveText={
                  formState.errors &&
                  (formState.errors.rejectedReason?.message as string)
                }
                labelRequired
                label={`${t('label:reason')}`}>
                <NativeSelect
                  classNameOverride={{
                    group: '!pt-0',
                    loadingMessage: `${t('label:loading')}`,
                    noOptionsMessage: `${t('label:noOptions')}`
                  }}
                  isLoading={isLoadReject}
                  onChange={onChange}
                  configSelectOption={{
                    supportingText: ['name']
                  }}
                  size="sm"
                  value={value}
                  options={dataRejectReasonGroup}
                  placeholder={`${t('label:placeholder:select')}`}
                  destructive={
                    formState.errors && !!formState.errors.rejectedReason
                  }
                />
              </FormControlItem>
            )
          }}
        />
      </div>
      <div className="mb-5">
        <Controller
          control={control}
          name="sendRejectEmail"
          defaultValue={false}
          render={({ field: { onChange, value } }) => {
            return (
              <FormControlItem>
                <Toggle
                  isChecked={value}
                  name="view-switch"
                  onCheckedChange={(checked) => {
                    onChange(checked)
                  }}
                  size="sm"
                  text={`${t('candidates:notifyCandidateByEmail')}`}
                  toggle="trailing"
                />
              </FormControlItem>
            )
          }}
        />
      </div>
      <If condition={isSendRejectMessage}>
        <If
          condition={
            applicant?.createdBy && applicant?.incoming === 'internal'
          }>
          <div className="mb-5 flex flex-row items-center rounded bg-gray-50 px-[4px] py-[4px]">
            <IconWrapper name="Info" size={16} />
            <span
              className="ml-2 text-sm font-normal text-gray-600"
              dangerouslySetInnerHTML={{
                __html: t('candidates:notifyCandidateSourcedByUser', {
                  name: applicant?.createdBy?.fullName
                })
              }}
            />
          </div>
        </If>
        <div className="mb-5">
          <EmailEditor
            label={`${t('label:emailContent')}`}
            control={control}
            setValue={setValue}
            setError={setError}
            formState={formState}
            clearErrors={clearErrors}
            showFields={[
              'to',
              'subject',
              'cc',
              'bcc',
              'emailTemplate',
              'htmlBody'
            ]}
            defaultTemplateEmail={defaultTemplateEmail}
            placeholderValue={placeholderValue}
            isShowComposeEmail={isShowComposeEmail}
            onSubmitUpdateEmailProfile={onSubmitUpdateEmailProfile}
            isLoadingUpdateEmailProfile={isLoadingUpdateEmailProfile}
            disabledFields={['to']}
          />
        </div>
      </If>
      <div className="-mx-4 mt-6 tablet:-mx-6">
        <div className="flex justify-end space-x-3 px-4 tablet:px-6">
          <Button
            type="secondary"
            size="sm"
            onClick={() => {
              setApplicant()
              setOpen(false)
            }}
            label={`${t('button:cancel')}`}
          />
          <Button
            size="sm"
            isDisabled={isLoading || disableSubmitRejectButton}
            isLoading={isLoading}
            htmlType="submit"
            type="destructive"
            label={`${t('button:disqualify')}`}
          />
        </div>
      </div>
    </div>
  )
}

export default SubmitRejectCandidateForm
