import { endOfDay } from 'date-fns'
import { FC, useCallback } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AsyncSingleSearchWithSelect } from '~/core/ui/AsyncSingleSearchWithSelect'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper from '~/core/ui/IconWrapper'
import { SingleDatePicker } from '~/core/ui/SingleDatePicker'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import {
  addTzToDate,
  changeTimezone
} from '~/lib/features/calendar/utilities/helper-schedule-interview'
import useQueryHiringMembersList from '~/lib/features/candidates/hooks/use-query-hiring-members-list'
import { ICandidateApplicant } from '~/lib/features/candidates/types'
import MutationMarkAsHired from '~/lib/features/jobs/graphql/submit-mark-as-hired'
import schemaMarkAsHiredForm from '~/lib/features/jobs/schema/validation-mark-as-hired'
import { IMarkAsHiredForm } from '~/lib/features/jobs/types'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'

const MarkAsHiredModal: FC<{
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  setOpenMarkAsHiredSuccess: (value: boolean) => void
  applicantCurrent?: {
    item?: ICandidateApplicant & { jobStageId?: number }
    callback?: () => void
  }
  setApplicantMarkAsHired: (value: ICandidateApplicant) => void
}> = ({
  open,
  setOpen,
  title,
  setOpenMarkAsHiredSuccess,
  applicantCurrent,
  setApplicantMarkAsHired
}) => {
  const { promiseHiringMemberOptions: fetchRecruiters } =
    useQueryHiringMembersList({
      jobId: Number(applicantCurrent?.item?.job?.id)
    })

  const { trigger, isLoading } = useSubmitCommon(MutationMarkAsHired)
  const setToast = useBoundStore((state) => state.setToast)
  const user = useBoundStore((state) => state.user)
  const { t, i18n } = useTranslation()

  const onSubmitMarkAsHired = useCallback(
    async (data: IMarkAsHiredForm) => {
      trigger({
        hiredDate: data?.hiredDate
          ? addTzToDate(String(endOfDay(data.hiredDate)), String(user.timezone))
          : undefined,
        onboardDate: data?.onboardDate
          ? addTzToDate(
              String(endOfDay(data.onboardDate)),
              String(user.timezone)
            )
          : null,
        id: Number(applicantCurrent?.item?.id),
        jobStageId: applicantCurrent?.item?.jobStageId,
        hiredById: Number(data?.hiredById?.value)
      })
        .then((result) => {
          const { applicantsMarkAsHired } = result.data
          if (applicantsMarkAsHired) {
            applicantCurrent?.callback && applicantCurrent?.callback()
            setToast({
              open: true,
              type: 'success',
              title: `${t('notification:mark_as_hired:success', {
                name: applicantCurrent?.item?.profile?.fullName
              })}`
            })
            setApplicantMarkAsHired(applicantsMarkAsHired.applicant)
          }
          return
        })
        .finally(() => {
          setOpenMarkAsHiredSuccess(true)
          setOpen(false)
        })
    },
    [trigger, applicantCurrent?.item?.id]
  )

  return (
    <Dialog
      className="min-w-[480px]"
      open={open}
      size="sm"
      onOpenChange={setOpen}
      isPreventAutoFocusDialog={true}
      label={`${t('label:markAsHired')}`}
      description={
        <>
          <div className="mb-1 flex items-center">
            <div>
              <IconWrapper
                name="User"
                size={16}
                className="mr-2 text-gray-500"
              />
            </div>
            <Tooltip
              content={
                applicantCurrent?.item?.profile?.fullName ||
                applicantCurrent?.item?.profile?.email ||
                ''
              }>
              <TypographyText className="line-clamp-1 text-sm text-gray-900">
                {applicantCurrent?.item?.profile?.fullName ||
                  applicantCurrent?.item?.profile?.email ||
                  ''}
              </TypographyText>
            </Tooltip>
          </div>
          <div className="flex items-center">
            <div>
              <IconWrapper
                name="Briefcase"
                size={16}
                className="mr-2 text-gray-500"
              />
            </div>
            <Tooltip content={title}>
              <TypographyText className="line-clamp-1 text-sm text-gray-900">
                {title}
              </TypographyText>
            </Tooltip>
          </div>
        </>
      }
      headingClassName="pb-4 tablet:pb-5 border-b-[1px] border-gray-100 mb-4">
      <DynamicImportForm
        isShowDebug={false}
        id="reject-reasons-form"
        className="w-full"
        schema={schemaMarkAsHiredForm()}
        defaultValue={{
          hiredDate: applicantCurrent?.item?.hiredDate
            ? new Date(applicantCurrent.item.hiredDate)
            : changeTimezone({
                date: new Date(),
                timezone: user?.timezone
              }),
          hiredById: applicantCurrent?.item?.hiredBy
            ? {
                value: String(applicantCurrent.item.hiredBy.id),
                avatar:
                  applicantCurrent.item.hiredBy.avatarVariants?.thumb?.url,
                avatarVariants: applicantCurrent.item.hiredBy.avatarVariants,
                supportingObj: {
                  defaultColour: applicantCurrent.item.hiredBy.defaultColour,
                  description: '',
                  name: String(
                    applicantCurrent.item.hiredBy.fullName ||
                      applicantCurrent.item.hiredBy.email
                  )
                }
              }
            : {
                value: user?.id ? user?.id.toString() : undefined,
                avatar: user.avatarVariants?.thumb?.url,
                avatarVariants: user.avatarVariants,
                supportingObj: {
                  defaultColour: user.defaultColour,
                  description: '',
                  name: String(user.fullName || user.email)
                }
              },
          onboardDate: applicantCurrent?.item?.onboardDate
            ? new Date(applicantCurrent.item.onboardDate)
            : undefined
        }}
        onSubmit={onSubmitMarkAsHired}>
        {({ formState, control, setValue, setError, getValues }) => {
          return (
            <div>
              <div className="mb-4">
                <FormControlItem label={`${t('label:hireDate')}`} labelRequired>
                  <Controller
                    control={control}
                    name="hiredDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <SingleDatePicker
                          locale={i18n.language}
                          className="w-full"
                          config={{
                            id: 1,
                            defaultOpen: false,
                            onChange,
                            value: value,
                            showClearIndicator: false,
                            disabled: {
                              before: changeTimezone({
                                date: applicantCurrent?.item?.createdAt || '',
                                timezone: user?.timezone
                              }),
                              after: changeTimezone({
                                date: new Date(),
                                timezone: user?.timezone
                              })
                            }
                          }}
                          placeholder={`${t('label:placeholder:selectDate')}`}
                          size="sm"
                        />
                      )
                    }}
                  />
                </FormControlItem>
              </div>
              <div className="mb-4">
                <FormControlItem label={`${t('label:startDate')}`}>
                  <Controller
                    control={control}
                    name="onboardDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <SingleDatePicker
                          locale={i18n.language}
                          className="w-full"
                          config={{
                            id: 2,
                            defaultOpen: false,
                            onChange,
                            value: value,
                            showClearIndicator: true,
                            onClear: () => onChange(undefined),
                            disabled: {
                              before: changeTimezone({
                                date: applicantCurrent?.item?.createdAt || '',
                                timezone: user?.timezone
                              })
                            }
                          }}
                          placeholder={`${t('label:placeholder:selectDate')}`}
                          size="sm"
                        />
                      )
                    }}
                  />
                </FormControlItem>
              </div>
              <div className="mb-4">
                <Controller
                  control={control}
                  name="hiredById"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlItem
                        destructive={
                          formState.errors && !!formState.errors.hiredById
                        }
                        destructiveText={
                          formState.errors &&
                          (formState.errors.hiredById?.message as string)
                        }
                        label={`${t('label:hiredBy')}`}
                        labelRequired>
                        <AsyncSingleSearchWithSelect
                          promiseOptions={fetchRecruiters}
                          isSearchable
                          size="sm"
                          isClearable={false}
                          onChange={onChange}
                          placeholder={`${t('label:placeholder:select')}`}
                          configSelectOption={{
                            supportingText: ['name', 'description'],
                            avatar: true,
                            isHideAvatarSelectedOption: true
                          }}
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          value={value}
                          destructive={
                            formState.errors && !!formState.errors.hiredById
                          }
                          menuPlacement="top"
                        />
                      </FormControlItem>
                    )
                  }}
                />
              </div>
              <div className="-mx-4 mt-6 tablet:-mx-6">
                <div className="flex justify-end space-x-3 px-4 tablet:px-6">
                  <Button
                    type="secondary"
                    size="sm"
                    onClick={() => setOpen(false)}
                    label={`${t('button:cancel')}`}
                  />
                  <Button
                    size="sm"
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    htmlType="submit"
                    label={`${t('button:markAsHired')}`}
                  />
                </div>
              </div>
            </div>
          )
        }}
      </DynamicImportForm>
    </Dialog>
  )
}

export default MarkAsHiredModal
