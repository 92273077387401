import { useCallback, useState } from 'react'
import { Maybe } from '~/core/@types/global'

type State<Data, ErrorType> =
  | {
      data: any
      loading: false
      success: true
      refetch: boolean
      error: Maybe<ErrorType>
    }
  | {
      data: undefined
      loading: true
      success: false
      refetch: boolean
      error: Maybe<ErrorType>
    }
  | {
      data: undefined
      loading: false
      success: false
      refetch: boolean
      error: Maybe<ErrorType>
    }

/**
 * @name useRequestState
 * @description A hook to manage the state of a request.
 *
 * Useful to "hookify" async functions and make them play well with React.
 *
 * Example:
 * const { setLoading, setData, setError } = useRequestState();
 *
 * async function request() {
 *   setLoading(true);
 *
 *   try {
 *      const data = await fetch('/api');
 *     setData(data);
 *   } catch (error) {
 *     setError(error);
 *   }
 * }
 */
export function useRequestState<Data = unknown, ErrorType = unknown>() {
  const [state, setState] = useState<State<Data, ErrorType>>({
    refetch: false,
    loading: false,
    success: false,
    error: undefined,
    data: undefined
  })

  const setRefetch = useCallback((refetch: boolean) => {
    setState({
      refetch,
      loading: false,
      success: false,
      data: undefined,
      error: undefined
    })
  }, [])

  const setLoading = useCallback((loading: boolean) => {
    setState({
      loading,
      refetch: false,
      success: false,
      data: undefined,
      error: undefined
    })
  }, [])

  const setData = useCallback((data: Data) => {
    setState({
      data,
      refetch: false,
      success: true,
      loading: false,
      error: undefined
    })
  }, [])

  const setError = useCallback((error: ErrorType) => {
    setState({
      data: undefined,
      refetch: false,
      loading: false,
      success: false,
      error
    })
  }, [])

  const resetState = useCallback(() => {
    setState({
      refetch: false,
      loading: false,
      success: false,
      error: undefined,
      data: undefined
    })
  }, [])

  return {
    state,
    setState,
    setRefetch,
    setLoading,
    setData,
    setError,
    resetState
  }
}
