// @ts-expect-error
export default function debounce(fn, delay = 250) {
  // @ts-expect-error
  let timeout

  // @ts-expect-error
  return (...args) => {
    // @ts-expect-error
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
