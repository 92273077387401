import {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo
} from 'react'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { FilterDataHolderContext } from '../Filter'
import { OptionDocument } from './FilterItemCombobox'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { SelectProps } from '~/core/ui/Select'

export type FilterControlItemType = { name: string }
export type FilterControl<T> = {
  value: T
  onChange: (value: T, options?: { triggerFilterChange: boolean }) => void
  onFilterSubmit?: () => void
}
export const createFilterControl = <P extends {}, T = any>(
  Component: (
    props: P & FilterControl<T> & FilterControlItemType
  ) => React.ReactElement | null
) => {
  const FilterControl = (itemProps: P & FilterControlItemType) => {
    const { onFilterItemChange, getFilterItemValue, onFilterSubmit } =
      useContext(FilterDataHolderContext)
    const value = useMemo(
      () => getFilterItemValue && getFilterItemValue(itemProps.name),
      [getFilterItemValue]
    )
    const onChange: FilterControl<T>['onChange'] = useCallback(
      (value, options) =>
        onFilterItemChange &&
        onFilterItemChange(itemProps.name, value, options),
      [onFilterItemChange]
    )
    return (
      <Component
        value={value}
        onChange={onChange}
        onFilterSubmit={onFilterSubmit}
        {...itemProps}
      />
    )
  }
  return FilterControl
}

interface IPromiseSearchOption {
  search: string
  page?: number
  limit?: number
}

export const createOptionFetcher = (
  Component: (props: any) => React.ReactElement | null
) => {
  const OptionFetcher = <Data, Variables>(props: {
    optionsFromDocumentNode: OptionDocument<Data, Variables>
  }) => {
    const optionsFromDocumentNode = props.optionsFromDocumentNode
    const { clientGraphQL } = useContextGraphQL()
    const ontionFetcher =
      optionsFromDocumentNode &&
      ((
        searchParams: IPromiseSearchOption
      ): Promise<{ metadata?: { totalCount: number }; collection: never[] }> =>
        clientGraphQL
          .query(
            optionsFromDocumentNode.documentNode,
            typeof optionsFromDocumentNode.variable === 'function'
              ? (
                  optionsFromDocumentNode.variable as (
                    searchParams: IPromiseSearchOption
                  ) => Variables
                )(searchParams)
              : optionsFromDocumentNode.variable
          )
          .toPromise()
          .then(
            (result: {
              error: { graphQLErrors: Array<object>; networkError?: object }
              data: Data
            }) => {
              if (result.error) {
                return catchErrorFromGraphQL({
                  error: result.error
                })
              }

              return optionsFromDocumentNode?.mapping(result.data)
            }
          ))
    return <Component {...props} options={ontionFetcher} />
  }
  return OptionFetcher
}
