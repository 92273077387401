const QueryCreateInterviewMutation = `
  mutation (
    $fromDatetime: ISO8601DateTime,
    $toDatetime: ISO8601DateTime,
    $timezone: String!,
    $eventType: InterviewEvent!,
    $meetingUrl: String,
    $sendEmailToCandidate: Boolean,
    $sendEmailToAttendees: Boolean,
    $locationId: Int,
    $emailTemplateId: Int,
    $attendeeIds: [Int!],
    $subjectAttendees: String,
    $subjectCandidate: String,
    $ccAttendees: [String!],
    $bccAttendees: [String!],
    $htmlBodyAttendees: String,
    $htmlBodyCandidate: String,
    $attachments: [File!],
    $profileId: Int,
    $applicantId: Int!
    $jobStageId: Int!,
    $remindSchedule: Boolean,
    $remindFeedback: Boolean!,
    $jobIkitId: Int,
    $timeSlots: [JSON!]
  ) {
    interviewsCreate(
      input: {
        fromDatetime: $fromDatetime,
        toDatetime: $toDatetime,
        timezone: $timezone,
        eventType: $eventType,
        meetingUrl: $meetingUrl,
        sendEmailToCandidate: $sendEmailToCandidate,
        sendEmailToAttendees: $sendEmailToAttendees,
        profileId: $profileId,
        applicantId: $applicantId,
        locationId: $locationId,
        emailTemplateId: $emailTemplateId,
        attendeeIds: $attendeeIds,
        subjectAttendees: $subjectAttendees,
        subjectCandidate: $subjectCandidate,
        ccAttendees: $ccAttendees,
        bccAttendees: $bccAttendees,
        htmlBodyAttendees: $htmlBodyAttendees,
        htmlBodyCandidate: $htmlBodyCandidate,
        attachments: $attachments,
        jobStageId: $jobStageId,
        remindSchedule: $remindSchedule,
        remindFeedback: $remindFeedback,
        jobIkitId: $jobIkitId,
        timeSlots: $timeSlots
      }
    ) {
      interview {
        id
        fromDatetime
        toDatetime
        interviewTimeSlots {
          fromDatetime
          toDatetime
        }
      }
    }
  }
`

export default QueryCreateInterviewMutation
