import { gql } from 'urql'

export const deletePlacementMutation = gql<
  {
    placementsDelete: {
      success: boolean
    }
  },
  {
    id: number
  }
>`
  mutation ($id: Int!) {
    placementsDelete(input: { id: $id }) {
      success
    }
  }
`
