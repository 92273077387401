import { useTranslation } from 'react-i18next'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import QueryGetRejectReasonGroup from '../graphql/query-reject-reason-group'
import { IRejectReasonGroup } from '../types'

export function useQueryRejectReasonGroupList({
  variables,
  shouldPause = true
}: {
  variables: {}
  shouldPause?: boolean
}) {
  const { t } = useTranslation()
  const {
    trigger,
    isLoading,
    data: response,
    error
  } = useQueryGraphQL({
    query: QueryGetRejectReasonGroup,
    variables,
    shouldPause
  })
  const collection = response?.rejectedReasonsListByGroup || {}
  let cloneData: IRejectReasonGroup[] = []
  Object.keys(collection).map((keyGroup) => {
    cloneData.push({
      value: keyGroup,
      label:
        keyGroup === 'rejectedByUs'
          ? `${t('label:disqualifyByUs')}`
          : `${t('label:disqualifyByCandidate')}`,
      options: collection[keyGroup].map(
        (reason: { id: string; label: string }) => ({
          value: reason.id,
          supportingObj: {
            name: reason.label
          }
        })
      )
    })
  })

  return {
    trigger,
    isLoading,
    data: cloneData,
    error
  }
}
