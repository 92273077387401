const MutationMarkAsHired = `
  mutation (
    $id: Int!,
    $jobStageId: Int!,
    $hiredById: Int!,
    $hiredDate: ISO8601DateTime!,
    $onboardDate: ISO8601DateTime
  ) {
    applicantsMarkAsHired(
      input: {
        id: $id,
        jobStageId: $jobStageId,
        hiredById: $hiredById,
        hiredDate: $hiredDate,
        onboardDate: $onboardDate,
      }
    ) {
    applicant {
        id
        job {
          title
        }
        timeToHire
        hiredBy {
          id
          email
          fullName
          avatarVariants
          defaultColour
        }
        referral {
          user {
            fullName
            avatarVariants
            defaultColour
          }
        }
        createdBy {
          fullName
          avatarVariants
          defaultColour
        }

        profile{
          sourcedDescription
        }
      }
    }
  }
`

export default MutationMarkAsHired
