import { useMemo, useState } from 'react'

export function useAsyncAction<T extends any[], R>(
  action: (...args: T) => Promise<R>,
  deps: any[] = []
): [(...args: T) => Promise<R>, boolean] {
  const [loading, setLoading] = useState(false)

  return [
    useMemo(
      () =>
        (...args: T) => {
          setLoading(true)
          return action(...args).finally(() => {
            setLoading(false)
          })
        },
      [...deps]
    ),
    loading
  ]
}
export default useAsyncAction
