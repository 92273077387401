import { z } from 'zod'

const schemaMarkAsHiredForm = () => {
  return z.object({
    hiredDate: z.date(),
    onboardDate: z.date().optional(),
    hiredById: z.object({ value: z.string() }).optional()
  })
}

export default schemaMarkAsHiredForm
