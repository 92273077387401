import { AnyVariables, TypedDocumentNode } from 'urql'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import { IEmailTemplate } from '../../settings/email-templates/types'

export function useQueryGetDefaultRejectTemplate({
  query,
  variables,
  shouldPause = true
}: {
  query: TypedDocumentNode<
    {
      applicantRejectedReasonsList?: {
        metadata?: {
          extras?: {
            default_email_template: IEmailTemplate
          }
        }
      }
    },
    AnyVariables
  >
  variables: object
  shouldPause?: boolean
}) {
  const {
    trigger,
    isLoading,
    data: response,
    error
  } = useQueryGraphQL({
    query,
    variables,
    shouldPause
  })
  const data =
    response?.applicantRejectedReasonsList?.metadata?.extras
      ?.default_email_template

  return {
    trigger,
    isLoading,
    data,
    error
  }
}

export function useQueryApplicantRejectedReasons({
  query,
  variables,
  shouldPause = true
}: {
  query: TypedDocumentNode<
    {
      applicantRejectedReasonsList: {
        collection: Array<{
          rejected_reason: string
          label: string
        }>
        metadata: {
          totalCount: number
        }
      }
    },
    AnyVariables
  >
  variables: object
  shouldPause?: boolean
}) {
  const {
    trigger,
    isLoading,
    data: response,
    error
  } = useQueryGraphQL({
    query,
    variables,
    shouldPause
  })
  const data = (response?.applicantRejectedReasonsList?.collection || []).map(
    (item: { rejected_reason: string; label: string }) => ({
      value: item.rejected_reason,
      supportingObj: {
        name: item.label
      }
    })
  )

  return {
    trigger,
    isLoading,
    data,
    error
  }
}
