import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import RejectApplicantModal from '~/components/Candidates/Profile/components/Reject/RejectApplicantModal'
import { IEmailForm } from '~/components/SendMailFormControl/EmailContentEditor'
import configuration from '~/configuration'
import { IRouterWithID, IToast } from '~/core/@types/global'
import { PUBLIC_APP_URL } from '~/core/constants/env'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import MutationRejectApplicants from '~/lib/features/candidates/graphql/mutation-reject-applicant'
import MutationUpdateEmailProfile from '~/lib/features/candidates/graphql/mutation-update-email-profile'
import { QueryGetDefaultTemplateRejctApplicant } from '~/lib/features/candidates/graphql/query-reject-reasons-applicants'
import { useQueryGetDefaultRejectTemplate } from '~/lib/features/candidates/hooks/use-query-candidate-applicants-reject-reason'
import { ICandidateApplicant } from '~/lib/features/candidates/types'
import { JOB_APPLICANT_STATUS } from '~/lib/features/jobs/utilities/enum'
import useWorkSpace from '~/lib/features/settings/workspace/hooks/use-workspace'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'

const RejectApplicantView: FC<{
  email?: string[]
  id?: IRouterWithID
  fullName?: string
  onEmailUpdateProfile: () => void
  updateLocalRecord?: (
    dataUpdate: ICandidateApplicant | ICandidateApplicant[]
  ) => void
  setApplicant: (applicant?: ICandidateApplicant) => void
  applicant?: ICandidateApplicant
  setOpen: (open: boolean) => void
  open: boolean
  setRefetch?: (refetch: boolean) => void
}> = ({
  email,
  id,
  fullName,
  onEmailUpdateProfile,
  updateLocalRecord,
  applicant,
  setApplicant,
  setOpen,
  open,
  setRefetch
}) => {
  const { t } = useTranslation()
  const setToast = useBoundStore((state) => state.setToast)
  const { tenantShow: dataCompany, fetchWorkspace } = useWorkSpace({
    shouldPause: true
  })

  const canonical_url = useMemo(() => {
    return `${PUBLIC_APP_URL}${
      dataCompany?.careerSiteSettings?.canonical_url || ''
    }`
  }, [dataCompany])

  const {
    trigger: triggerGetDefaultRejectTemplate,
    data: defaultTemplateEmail
  } = useQueryGetDefaultRejectTemplate({
    query: QueryGetDefaultTemplateRejctApplicant,
    variables: {},
    shouldPause: false
  })
  useEffect(() => {
    if (open) {
      triggerGetDefaultRejectTemplate()
      fetchWorkspace()
    }
  }, [open])

  const {
    trigger: triggerUpdateEmailProfile,
    isLoading: isLoadingUpdateEmailProfile
  } = useSubmitCommon(MutationUpdateEmailProfile)

  const onSubmitUpdateEmailProfile = useCallback(
    async (params: { email: string }) => {
      if (isLoadingUpdateEmailProfile) {
        return
      }
      triggerUpdateEmailProfile({
        id: Number(id),
        email: [params.email]
      }).then((result) => {
        if (result.error) {
          return
        }
        const { profilesUpdate } = result.data
        if (profilesUpdate?.profile?.id) {
          onEmailUpdateProfile && onEmailUpdateProfile()
          setToast({
            open: true,
            type: 'success',
            title: t('notification:saveChanges')
          })
        }
        return true
      })
    },
    [
      isLoadingUpdateEmailProfile,
      triggerUpdateEmailProfile,
      id,
      onEmailUpdateProfile,
      setToast,
      t
    ]
  )

  const {
    trigger: triggerSubmitRejectApplicant,
    isLoading: isLoadingRejectApplicant
  } = useSubmitCommon(MutationRejectApplicants)

  const onSubmitRejectApplicant = useCallback(
    async (params: IEmailForm) => {
      if (isLoadingRejectApplicant) {
        return
      }

      let sendRejectEmail = params.sendRejectEmail
      let cc = sendRejectEmail
        ? (params?.cc || []).map((e) => e.value)
        : undefined
      let bcc = sendRejectEmail
        ? (params?.bcc || []).map((e) => e.value)
        : undefined
      const dataSubmit = {
        id: Number(applicant?.id),
        status: JOB_APPLICANT_STATUS.rejected,
        rejectedReason: params?.rejectedReason?.supportingObj?.name || '',
        rejectedReasonId: Number(params.rejectedReason?.value) || '',
        sendRejectEmail,
        subject: sendRejectEmail ? params.subject : undefined,
        cc,
        bcc,
        htmlBody: sendRejectEmail ? params.htmlBody : undefined,
        emailTemplateId: sendRejectEmail
          ? params?.emailTemplate?.value
            ? Number(params?.emailTemplate?.value)
            : undefined
          : undefined
      }

      triggerSubmitRejectApplicant(dataSubmit).then((result) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            page: configuration.path.candidates.list,
            setToast
          })
        }
        const { applicantsUpdate } = result.data
        if (applicantsUpdate?.applicant) {
          updateLocalRecord && updateLocalRecord(applicantsUpdate?.applicant)
          setRefetch && setRefetch(true)
          onEmailUpdateProfile && onEmailUpdateProfile()
          setApplicant()
          setOpen(false)
          const stateToast: IToast = {
            open: true,
            type: 'success',
            title: `${t('notification:candidateDisqualified')}`
          }
          if (sendRejectEmail) {
            stateToast.description = `${t(
              'notification:descriptionCandidateDisqualified'
            )}`
          }
          setToast(stateToast)
        }
        return true
      })
    },
    [
      isLoadingRejectApplicant,
      applicant?.id,
      triggerSubmitRejectApplicant,
      setToast,
      updateLocalRecord,
      setRefetch,
      setApplicant,
      setOpen
    ]
  )

  return (
    <RejectApplicantModal
      email={email}
      fullName={fullName}
      applicant={applicant}
      open={open}
      setOpen={setOpen}
      setApplicant={setApplicant}
      isLoadingRejectApplicant={isLoadingRejectApplicant}
      onSubmitUpdateEmailProfile={onSubmitUpdateEmailProfile}
      isLoadingUpdateEmailProfile={isLoadingUpdateEmailProfile}
      onSubmitRejectApplicant={onSubmitRejectApplicant}
      defaultTemplateEmail={defaultTemplateEmail}
      canonical_url={canonical_url}
    />
  )
}

export default RejectApplicantView
