import { Dispatch, FC, ReactElement, SetStateAction, useState } from 'react'
import { IPlacement } from '~/features/placements/placement'
import { ICandidateApplicant } from '~/lib/features/candidates/types'
import MarkAsHiredModal from './MarkAsHired/MarkAsHiredModal'
import MarkAsHiredSuccessModal from './MarkAsHired/MarkAsHiredSuccessModal'

export interface ChangeJobStageWithModalActionProps {
  setOpenMarkAsHired?: Dispatch<SetStateAction<boolean>>
  setOpenMarkAsHiredSuccess?: Dispatch<SetStateAction<boolean>>
  setApplicantMarkAsHired?: Dispatch<
    SetStateAction<ICandidateApplicant | undefined>
  >
  setApplicantCurrent?: Dispatch<
    SetStateAction<{
      item?: ICandidateApplicant & { jobStageId?: number }
      callback?: () => void
    }>
  >
  openHiringSuccessModel?: (args: {
    applicant: ICandidateApplicant
    placement: IPlacement
  }) => void
}

interface ChangeJobStageWithModalViewProps {
  jobTitle?: string
  children(data: ChangeJobStageWithModalActionProps): ReactElement
}

const ChangeJobStageWithModalView: FC<ChangeJobStageWithModalViewProps> = ({
  children,
  jobTitle
}) => {
  const [openMarkAsHired, setOpenMarkAsHired] = useState<boolean>(false)
  const [openMarkAsHiredSuccess, setOpenMarkAsHiredSuccess] =
    useState<boolean>(false)
  const [applicantCurrent, setApplicantCurrent] = useState<{
    item?: ICandidateApplicant
    callback?: () => void
  }>({
    item: undefined,
    callback: undefined
  })
  const [placement, setPlacement] = useState<IPlacement>()
  const [applicantMarkAsHired, setApplicantMarkAsHired] =
    useState<ICandidateApplicant>()
  const openHiringSuccessModel: ChangeJobStageWithModalActionProps['openHiringSuccessModel'] =
    (args) => {
      setApplicantMarkAsHired(args.applicant)
      setPlacement(args.placement)
      setOpenMarkAsHiredSuccess(true)
    }
  return (
    <>
      {typeof children !== 'undefined'
        ? children({
            setOpenMarkAsHired,
            setOpenMarkAsHiredSuccess,
            setApplicantMarkAsHired,
            setApplicantCurrent,
            openHiringSuccessModel
          })
        : null}

      {applicantCurrent?.item && (
        <MarkAsHiredModal
          open={openMarkAsHired}
          setOpen={setOpenMarkAsHired}
          title={jobTitle || applicantCurrent?.item?.job?.title || ''}
          applicantCurrent={applicantCurrent}
          setOpenMarkAsHiredSuccess={setOpenMarkAsHiredSuccess}
          setApplicantMarkAsHired={setApplicantMarkAsHired}
        />
      )}

      {applicantMarkAsHired && (
        <MarkAsHiredSuccessModal
          placement={placement}
          open={openMarkAsHiredSuccess}
          setOpen={setOpenMarkAsHiredSuccess}
          applicantMarkAsHired={applicantMarkAsHired}
          setApplicantMarkAsHired={setApplicantMarkAsHired}
        />
      )}
    </>
  )
}

export default ChangeJobStageWithModalView
