import { useTranslation } from 'react-i18next'
import { FC, useMemo } from 'react'
import { IEmailForm } from '~/components/SendMailFormControl/EmailContentEditor'
import { Dialog } from '~/core/ui/Dialog'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { schemaRejectApplicant } from '~/lib/features/candidates/schema/validation-create-profile'
import { ICandidateApplicant } from '~/lib/features/candidates/types'
import { IEmailTemplate } from '~/lib/features/settings/email-templates/types'
import useBoundStore from '~/lib/store'
import SubmitRejectCandidateForm from '../../../SubmitRejectCandidateForm'
import IconWrapper from '~/core/ui/IconWrapper'
import { TypographyText } from '~/core/ui/Text'
import { Divider } from '~/core/ui/Divider'

const RejectApplicantModal: FC<{
  open: boolean
  setOpen: (open: boolean) => void
  applicant?: ICandidateApplicant
  setApplicant: (param?: ICandidateApplicant) => void
  onSubmitUpdateEmailProfile: (params: { email: string }) => Promise<void>
  onSubmitRejectApplicant: (params: IEmailForm) => Promise<void>
  isLoadingUpdateEmailProfile: boolean
  isLoadingRejectApplicant: boolean
  email?: string[]
  fullName?: string
  defaultTemplateEmail?: IEmailTemplate
  canonical_url?: string
}> = ({
  open,
  setOpen,
  applicant,
  setApplicant,
  onSubmitUpdateEmailProfile,
  onSubmitRejectApplicant,
  isLoadingUpdateEmailProfile,
  isLoadingRejectApplicant,
  email,
  fullName,
  defaultTemplateEmail,
  canonical_url
}) => {
  const { t } = useTranslation()
  const user = useBoundStore((state) => state.user)

  const placeholderValue = useMemo(() => {
    let name = fullName || ''
    let job_link = `${canonical_url}${applicant?.jobId}`
    return {
      candidate_email: email?.join(', ') || '',
      candidate_name: `<strong>${name}</strong>`,
      candidate_fullname: `<strong>${name}</strong>`,
      recruiter_email: applicant?.job?.owner?.email || '',
      recruiter_fullname: applicant?.job?.owner?.fullName || '',
      job_title: `<strong>${applicant?.job?.title}</strong>`,
      job_link: `<a href="${job_link}">${t(
        'candidates:candidateSendMail:viewJobDetail'
      )}</a>`,
      company_name: `<strong>${user?.currentTenant?.name}</strong>`,
      career_page: `<a href="${canonical_url}">${user?.currentTenant?.name}</a>`
    }
  }, [
    fullName,
    canonical_url,
    applicant?.jobId,
    applicant?.job?.owner?.email,
    applicant?.job?.owner?.fullName,
    applicant?.job?.title,
    email,
    user?.currentTenant?.name
  ])

  return (
    <Dialog
      className="min-w-[680px]"
      open={open}
      size="sm"
      onOpenChange={setOpen}
      isPreventAutoFocusDialog={true}
      label={`${t('label:disqualifyCandidate')}`}
      description={
        <>
          <div className="mb-1 flex items-center">
            <IconWrapper name="User" size={16} className="mr-2 text-gray-500" />
            <TypographyText className="text-sm text-gray-900">
              {fullName}
            </TypographyText>
          </div>
          <div className="flex items-center">
            <IconWrapper
              name="Briefcase"
              size={16}
              className="mr-2 text-gray-500"
            />
            <TypographyText className="text-sm text-gray-900">
              {applicant?.job?.title}
            </TypographyText>
          </div>
        </>
      }
      headingClassName="pb-4 tablet:pb-5 border-b-[1px] border-gray-100 mb-4">
      <DynamicImportForm
        isShowDebug={false}
        id="reject-reasons-form"
        className="w-full"
        schema={schemaRejectApplicant(t)}
        onSubmit={onSubmitRejectApplicant}>
        {({
          formState,
          control,
          setValue,
          setError,
          clearErrors,
          getValues
        }) => {
          return (
            <SubmitRejectCandidateForm
              control={control}
              setValue={setValue}
              formState={formState}
              setOpen={setOpen}
              isLoading={isLoadingRejectApplicant}
              setApplicant={setApplicant}
              applicant={applicant}
              defaultTemplateEmail={defaultTemplateEmail}
              placeholderValue={placeholderValue}
              onSubmitUpdateEmailProfile={onSubmitUpdateEmailProfile}
              isLoadingUpdateEmailProfile={isLoadingUpdateEmailProfile}
              email={email}
              setError={setError}
              clearErrors={clearErrors}
            />
          )
        }}
      </DynamicImportForm>
    </Dialog>
  )
}

export default RejectApplicantModal
